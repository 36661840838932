<template>
  <div class="commonDate-container">
    <el-select class="dateType" v-model="activeType" @change="(val) => selectFun(val)">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <el-date-picker v-show="activeType == 'D'" class="dateClassName" v-model="dateParams" @change="(val) => changeDate(val, 'D')" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" clearable></el-date-picker>
    <el-date-picker v-show="activeType == 'M'" class="dateClassName" v-model="dateParams" @change="(val) => changeDate(val, 'M')" type="monthrange" range-separator="~" start-placeholder="开始月份" end-placeholder="结束月份" clearable></el-date-picker>
    <el-date-picker v-show="activeType == 'Y'" v-model="dateParamsY" @change="(val) => changeDate(val, 'Y')" type="year" placeholder="请选择年份" clearable></el-date-picker>
    <el-radio-group style="margin-left: 10px" v-model="radioValue" @change="changeRadio">
      <el-radio-button :label="1">今日</el-radio-button>
      <el-radio-button :label="2">昨日</el-radio-button>
      <el-radio-button :label="3">本周</el-radio-button>
      <el-radio-button :label="4">上周</el-radio-button>
      <el-radio-button :label="5">本月</el-radio-button>
      <el-radio-button :label="6">上月</el-radio-button>
      <el-radio-button :label="7">今年</el-radio-button>
      <el-radio-button :label="8">去年</el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
import { DateTransform, getLatelyDay, getSectionDate } from "../../../utils/index.js"
export default {
    props: ['radio', 'startDate', 'endDate'],
    //   props:{
    //       radio:{
    //           default: null
    //       }
    //   },
  data() {
    return {
      activeType: 'D',
      dateParams: [this.startDate,this.endDate],
      radioValue: this.radio,//双绑
      dateParamsY: "",
      options: [
        { value: "D", label: "按天"},
        { value: "M", label: "按月"},
        // { value: "Y", label: "按年"},
      ],
    };
  },
  methods: {
    selectFun (val) {
        this.dateParams= []
        this.radioValue= null
        this.dateParamsY= ""
        this.$emit('dateTypeFun', val)
    },
    changeRadio(e) {
        let date;
        this.activeType = 'D'
        switch (e) {
            case 1:
            date = [(new Date()), (new Date())];
            break;
            case 2:
            date = getLatelyDay(0);
            break;
            case 3:
            date = this.getWeekDate()
            break;
            case 4:
            date = this.getlastWeek();
            break;
            case 5:
            date = getSectionDate('month');
            break;
            case 6:
            date = getSectionDate('lastmonth');
            break;
            case 7:
            date = getSectionDate('year');
            break;
            case 8:
            date = getSectionDate('lastyear');
            break;
        }
        this.dateParams = date;
        this.$emit('commonGetDate', DateTransform(date[0]),  DateTransform(date[1]),e)
    },
    changeDate (val, type) {
        if (val) {
            switch(type) {
                case 'D': 
                this.$emit('commonGetDate', DateTransform(val[0]),  DateTransform(val[1])); 
                this.radioValue = null
                break;
                case 'M': this.$emit('commonGetDate', DateTransform(val[0]), this.commonEndM(val[1])); break;
                case 'Y': this.$emit('commonGetDate', DateTransform(val), this.commonEndY(val)); break;
            }
        } else {
            this.radioValue = null
        }
    },
    getlastWeek () { // 获取上周
        let myDate = new Date(new Date().getTime() - 7 * 24 * 3600 * 1000)
        let day = myDate.getDay()
        let time = myDate.getDate() - day + (day === 0 ? -6 : 1)
        let startTime = new Date(myDate.setDate(time))
        let startDateTime = startTime.getFullYear() + '-' + this.time(startTime.getMonth() + 1) + '-' + this.time(startTime.getDate())
        let endTime = new Date(myDate.setDate(time + 6))
        let endDateTime = endTime.getFullYear() + '-' + this.time(endTime.getMonth() + 1) + '-' + this.time(endTime.getDate())
        return [startDateTime, endDateTime]
        
    },
    getWeekDate () { // 获取本周日期
        let now = new Date(); //当前日期
        let nowDayOfWeek = now.getDay() || 7; //今天本周的第几天
        let nowDay = now.getDate(); //当前日
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        let weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek - 1));
        return [weekStartDate, new Date()]
    },
    resetFun (commonRadio, startDate, endDate) {
        if (commonRadio) {
            this.radioValue = commonRadio
        } else {
            this.radioValue = null
        }
        if (startDate && endDate) {
            this.dateParams = [startDate, endDate]
        } else {
            this.dateParams = []
        }
        this.activeType = 'D'
        this.dateParamsY = ''
    },
    commonEndM (date) { // 月份最后一天
        let newDate = new Date(date)
        let newYear = newDate.getFullYear();
        let newMonth = newDate.getMonth() + 1
        let newDay = newDate.getDate();
        let nextDate = new Date(newYear, newMonth, newDay).getTime() - 1000 * 60 * 60 * 24
        return DateTransform(nextDate);
    },
    commonEndY (date) { // 年份最后一天
        let newDate = new Date(date)
        let newYear = newDate.getFullYear();
        return newYear + '-12-31';
    },
    time (time) {
        if (time < 10) {
            return "0" + time;
        } else {
            return time;
        }
    }
  }
};
</script>
<style lang="less" scoped>
.commonDate-container {
    .dateClassName {
        width: 240px;
    }
    .dateType {
        width: 84px;
        margin-right: 10px;
    }
    /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
    }
}
</style>
