import fetch from "./xhr/index";

/**
 * 自定义报表-列表
 * @param
 */
export const reportList = (param) => fetch("GET", `/customize/customizeRecord/list`, param);

/**
 * 自定义报表-新增/编辑/是否公开
 * @param
 */
export const reportAddEdit = (param) => fetch("POST", `/customize/customizeRecord/addRecord`, param);

/**
 * 自定义报表-删除
 * @param
 */
export const reportDelete = (param) => fetch("GET", `/customize/customizeRecord/deleteRecord`, param);

/**
 * 自定义报表-查询结果列表
 * @param
 */
export const reportDetail = (param) => fetch("GET", `/customize/customizeRecord/getRecordResult`, param);

/**
 * 自定义报表配置-获取配置详情
 * @param
 */
export const reportSetDetail = (param) => fetch("GET", `/customize/customizeRecord/recordDetail`, param);

/**
 * 自定义报表配置-获取配置模板
 * @param
 */
export const reportSetTemplate = (param) => fetch("GET", `/customize/customizeRecord/getRecordElement`, param);

/**
 * 自定义报表配置-新增/编辑/排序
 * @param
 */
export const reportSetAddEdit = (param) => fetch("POST", `/customize/customizeRecord/addRecordDetail`, param);

/**
 * 自定义报表列表-排序
 * @param {type}
 * -1向上排序 1向下排序 当第一条和最后一条排序时，接口会提示错误信息
 * @param {id}
 */
export const reportSort = (param) => fetch("GET", `/customize/customizeRecord/sortAdjustment`, param);